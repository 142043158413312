import { createRouteRef, createSubRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'cloud-accounts',
});

export const accessExplorerRouteRef = createSubRouteRef({
  id: 'cloud-accounts/access-explorer',
  path: '/access-explorer',
  parent: rootRouteRef,
});
