import { createPermission } from '@backstage/plugin-permission-common';

export const cloudAccountsUpdatePermission = createPermission({
  name: 'cloud-account.update',
  attributes: { action: 'update' },
});

export const cloudAccountsReadAccessExplorerPermission = createPermission({
  name: 'cloud-account.readAccessExplorer',
  attributes: { action: 'read' },
});

export const cloudAccountsPermissions = [
  cloudAccountsUpdatePermission,
  cloudAccountsReadAccessExplorerPermission,
];
